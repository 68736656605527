import { axios } from "./../plugins/axios";
export function registered(parameter) {
  return axios.request({
    method: "post",
    params: {
      module: "app",
      action: "public",
      app: "c_register",
      store_type: 8,
      ...parameter
    }
  });
}
/**
 * 获取验证码
 * @param {String} phone 手机号
 * @param {String} randstr
 * @param {String} ticket
 */
export function getCode(params) {
  return axios.request({
    method: "post",
    params: {
      module: "app",
      action: "public",
      app: "secret_key",
      store_type: 8,
      ...params
    }
  });
}
