<template>
  <div class="box">
    <div class="download">
      <img src="@/assets/img/component/logo-icon@2x.png" alt="" />
      <div class="center">
        <div class="title">1号奢仓</div>
        <div class="sub">专业奢侈品、豪车共享租用平台</div>
      </div>
      <div class="btn" @click.stop="downloadApp">立即下载</div>
      <!-- <div class="qrcode" ref="qrCodeUrl"></div> -->
    </div>
    <!-- <van-dialog v-model="show" show-cancel-button>
      <img src="https://img01.yzcdn.cn/vant/apple-3.jpg" /> 
   <div class="qrcode" ref="qrCodeUrl">1231</div>
    </van-dialog> -->
  </div>
</template>

<script>
// import QRCode from "qrcodejs2";
export default {
  // data() {
  //   return {
  //     show: false
  //   };
  // },
  // mounted() {
  //   this.creatQrCode();
  // },
  methods: {
    // creatQrCode() {
    //   new QRCode(this.$refs.qrCodeUrl, {
    //     text: "https://a.app.qq.com/o/simple.jsp?pkgname=com.miwang.yhsc",
    //     width: 45,
    //     height: 45,
    //     colorDark: "#333333", //二维码颜色
    //     colorLight: "#ffffff",
    //     correctLevel: QRCode.CorrectLevel.H
    //   });
    // },
    downloadApp() {
      // this.show = !this.show;
      window.location.href =
        "https://a.app.qq.com/o/simple.jsp?pkgname=com.miwang.yhsc";
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  height: 108px;
}
.download {
  padding: 0 20px;
  height: 108px;
  background: #ffffff;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  img {
    width: 76px;
    height: 76px;
  }
  .center {
    display: flex;
    // align-items: center;
    height: 76px;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 20px;
    .title {
      font-size: 31px;
      font-weight: 600;
      color: #333333;
    }
    .sub {
      font-size: 25px;
      font-weight: 300;
      color: #999999;
    }
  }
  .btn {
    width: 168px;
    height: 56px;
    background: linear-gradient(0deg, #323234, #606061);
    border-radius: 28px;
    font-size: 25px;
    font-weight: 600;
    color: #ffffff;
    line-height: 56px;
    text-align: center;
    margin-left: auto;
  }
  .qrcode {
    margin-left: auto;
  }
}
</style>
