<template>
  <div class="bgc1" @click.stop="show = true">
    <download></download>
    <img class="top-bgc" src="@/assets/img/floorPage/homebgc1.png" alt="" />
    <div v-if="type == 2" class="top-bgc2">
      <!-- <img class="img1" src="@/assets/img/floorPage/homebgc2.png" alt="" /> -->
      <img
        class="btnimg"
        src="@/assets/img/floorPage/homebgcxiazai.png"
        alt=""
      />
    </div>
    <div v-if="type == 3" class="top-bgc3">
      <img src="../../assets/img/floorPage/top3tip.png" alt="" />
    </div>
    <div
      class="content"
      :class="{ topf: type == 2, topf3: type == 3, topf4: type == 4 }"
    >
      <img class="free-title" src="@/assets/img/floorPage/dapaimianfei.png" />
      <div class="free-title-content">
        <ul>
          <li v-for="(item, index) in goodList" :key="index">
            <img :src="item.path" alt="" />
            <p class="txt">{{ item.name }}</p>
            <div class="receive">0元领取</div>
          </li>
        </ul>
      </div>
      <img
        class="free-title mtp"
        src="@/assets/img/floorPage/rementuijian.png"
      />
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="./../../assets/img/floorPage/001.png" alt="" />
            <p>大牌包袋</p>
          </div>
          <div class="swiper-slide">
            <img src="./../../assets/img/floorPage/002.png" alt="" />
            <p>大牌手表</p>
          </div>
          <div class="swiper-slide">
            <img src="./../../assets/img/floorPage/003.png" alt="" />
            <p>大牌豪车</p>
          </div>
        </div>
      </div>
      <img
        class="free-title mtp"
        src="@/assets/img/floorPage/mianfeiling.png"
      />
      <div class="welfare">
        <img src="../../assets/img/floorPage/xinrenfuli.png" alt="" />
      </div>
      <div class="btn" @click.stop="goTo">
        {{ registrationSuccess ? "立即下载" : "注册" }}
      </div>
      <div style="height: 2rem"></div>
    </div>
    <van-popup
      v-model="show"
      closeable
      :close-on-click-overlay="false"
      round
      @click.stop
    >
      <div class="zhece-content">
        <van-form @submit="onSubmit" :show-error-message="false">
          <van-field
            v-model="phone"
            maxlength="11"
            label="手机号"
            placeholder="手机号"
            :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <van-field
            v-model="code"
            label="验证码"
            maxlength="6"
            placeholder="验证码"
            :rules="[{ required: true, message: '请填写验证码' }]"
          >
            <template #right-icon>
              <div v-show="codeShow" class="VerificationCode" @click="getCode">
                获取验证码
              </div>
              <div v-show="!codeShow" class="VerificationCode">
                {{ yzmTitle }}
              </div>
            </template>
          </van-field>
          <van-field
            v-model="password"
            label="密码"
            maxlength="16"
            placeholder="请输入密码"
            type="password"
            :rules="[{ required: true, message: '请填写验证码' }]"
          ></van-field>
          <div style="margin: 16px">
            <van-button round block type="info" native-type="submit"
              >注册</van-button
            >
          </div>
        </van-form>
      </div>
    </van-popup>
    <van-overlay
      class="show-download"
      :show="showDownload"
      @click.stop="showDownload = false"
    >
      <div class="wrapper">
        <img
          src="@/assets/img/channel/hongbao.png"
          alt=""
          @click.stop="download"
        />
      </div>
    </van-overlay>
    <t-captcha-js src="https://ssl.captcha.qq.com/TCaptcha.js"></t-captcha-js>
  </div>
</template>
<script>
import Swiper from "swiper";
import { registered, getCode } from "./../../api/login";
import download from "./../../components/download";
export default {
  components: {
    download,
    "t-captcha-js": {
      render(createElement) {
        return createElement("script", {
          attrs: { type: "text/javascript", src: this.src }
        });
      },
      props: {
        src: { type: String, required: true }
      }
    }
  },
  data() {
    return {
      registrationSuccess: 0,
      show: true,
      phone: "",
      code: "",
      password: "",
      codeShow: true,
      yzmTitle: "",
      showDownload: false,
      goodList: [
        {
          name: "AHC面膜x2",
          path: require("@/assets/img/floorPage/good2.png")
        },
        {
          name: "3CE唇釉",
          path: require("@/assets/img/floorPage/good6.png")
        },
        {
          name: "蓝药丸面膜x2盒",
          path: require("@/assets/img/floorPage/good1.png")
        },
        {
          name: "古驰罪爱男香水",
          path: require("@/assets/img/floorPage/good7.png")
        },
        {
          name: "范思哲男士香水",
          path: require("@/assets/img/floorPage/good8.png")
        },
        {
          name: "迪奥口红999",
          path: require("@/assets/img/floorPage/good3.png")
        },
        {
          name: "迪奥香水",
          path: require("@/assets/img/floorPage/good4.png")
        },
        {
          name: "古驰花之舞香水",
          path: require("../../assets/img/floorPage/good5.png")
        }
      ],
      type: 1,
      typeIndex: 1,
      typeList: [
        { type: "1", class: "bgc1", bgcolor: "#18191a" },
        { type: "2", class: "bgc2", bgcolor: "#18191a" },
        { type: "3", class: "bgc3", bgcolor: "#18191a" }
      ]
    };
  },
  created() {
    this.registrationSuccess = localStorage.registrationSuccess * 1;
    let type = this.$route.query.type;
    console.log(this.$route);
    this.type = type;
    this.typeIndex = this.typeList.findIndex(item => item.type == type);
  },
  mounted() {
    new Swiper(".swiper-container", {
      loop: true,
      autoplay: 2000,
      // slidesPerView: 3,
      spaceBetween: 20,
      // effect: "cube"
      effect: "coverflow",
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: "auto",
      coverflow: {
        rotate: 30, //slide做3d旋转时Y轴的旋转角度。默认50。
        // stretch: -stretchNum, //每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
        depth: 80, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
        slideShadows: false //开启slide阴影。默认 true。
      }
    });
  },
  methods: {
    showCodeImg() {
      // eslint-disable-next-line no-undef
      var captcha1 = new TencentCaptcha("2008794111", coderes => {
        console.log(coderes);
        const { randstr, ticket } = coderes;
        getCode({ mobile: this.phone, randstr, ticket }).then(res => {
          console.log(res);
          if (res.data.code == 200) {
            let time = 60;
            let timer = setInterval(() => {
              if (time == 0) {
                clearInterval(timer);
                this.codeShow = true;
              } else {
                this.codeShow = false;
                this.yzmTitle = time + "秒后重试";
                time--;
              }
            }, 1000);
            this.$toast("验证码获取成功");
          } else {
            this.$toast(res.data.message);
          }
        });
      });
      captcha1.show();
    },
    download() {
      window.location.href =
        "https://a.app.qq.com/o/simple.jsp?pkgname=com.miwang.yhsc";
      this.showDownload = false;
    },
    onSubmit() {
      console.log("提交");
      let reg = /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
      if (!reg.test(this.phone)) {
        this.$toast("请输入正确的手机号码");
        return;
      }
      registered({
        keyCode: this.code,
        phone: this.phone,
        rid: this.$route.query.rid,
        pwd: this.password
      }).then(res => {
        console.log(res);
        if (res.data.code == 200) {
          this.$toast("注册成功");
          localStorage.registrationSuccess = 1;
          this.registrationSuccess = localStorage.registrationSuccess;
          this.show = false;
          this.showDownload = true;
          // eslint-disable-next-line no-undef
          // this.$nextTick(() => {
          //   this.$dialog
          //     .confirm({
          //       title: "提示",
          //       message: "是否下载app",
          //       confirmButtonText: "下载"
          //     })
          //     .then(() => {
          //       // on close
          //       window.location.href =
          //         "https://a.app.qq.com/o/simple.jsp?pkgname=com.miwang.yhsc";
          //     });
          // });
        } else {
          this.$toast(res.data.message);
        }
      });
    },
    getCode() {
      if (!this.phone) {
        this.$toast("请输入手机号码");
        return;
      }
      let reg = /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
      if (!reg.test(this.phone)) {
        this.$toast("请输入正确的手机号码");
        return;
      }
      this.showCodeImg();
    },
    goTo() {
      if (this.registrationSuccess) {
        window.location.href =
          "https://a.app.qq.com/o/simple.jsp?pkgname=com.miwang.yhsc";
      } else {
        this.show = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.bgc1 {
  background: #18191a;
}
.bgc2 {
  background: #4075d8;
}
.bgc3 {
  background: #980e2a;
}
.top-bgc {
  width: 750px;
  height: 867px;
}
.topf {
  margin-top: -350px;
}
.topf3 {
  margin-top: -160px;
}
.top-bgc2 {
  background: url("./../../assets/img/floorPage/homebgc2.png") 0 0 no-repeat;
  background-size: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 1480px;
  .img1 {
    width: 750px;
    height: 1111px;
  }
  .btnimg {
    width: 750px;
    height: 700px;
  }
}
.top-bgc3 {
  background: url("./../../assets/img/floorPage/homegbc3.png") 0 0 no-repeat;
  background-size: 100%;
  height: 1200px;
  overflow: hidden;
  img {
    width: 685px;
    height: 257px;
    display: block;
    margin: auto;
    margin-top: 80px;
  }
}
.content {
  padding-top: 36px;
  .free-title {
    width: 517px;
    height: 36px;
    display: block;
    margin: auto;
  }
  .mtp {
    margin-top: 61px;
  }
  .free-title-content {
    width: 705px;
    height: 612px;
    margin: auto;
    margin-top: 50px;
    overflow-x: scroll;
    background-image: linear-gradient(120deg, #fcf0db, #e6b87b);
    border-radius: 20px;
    // display: flex;
    // align-items: center;
    ul {
      width: 912px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 33px;
      padding: 0 20px;
      text-align: center;
      li {
        width: 214px;
        height: 270px;
        background-color: #fff;
        border-radius: 7px;
        margin: 2px 0;
        img {
          width: 140px;
          height: 140px;
          margin: auto;
          display: block;
          margin-top: 17px;
        }
        .txt {
          line-height: 2;
          font-size: 21px;
        }
        .receive {
          width: 155px;
          height: 42px;
          background: #fbac33;
          border-radius: 21px;
          line-height: 42px;
          margin: auto;
          color: #fff;
        }
      }
    }
  }
}
.swiper-wrapper {
  height: 350px;
  margin-top: 100px;
  .swiper-slide {
    width: 256px;
    height: 320px;
    border-radius: 20px;
    background-color: #fff;
    img {
      width: 218px;
      height: 218px;
      display: block;
      margin: auto;
      margin-top: 20px;
      overflow: hidden;
    }
    p {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
    }
  }
}
.welfare {
  margin-top: 61px;
  img {
    width: 708px;
    height: 488px;
    display: block;
    margin: auto;
  }
}
.btn {
  background-image: linear-gradient(#fad8ae, #e59e6e);
  height: 80px;
  width: 630px;
  border-radius: 80px;
  text-align: center;
  line-height: 80px;
  font-size: 42px;
  color: #995a00;
  position: fixed;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 100;
}
.zhece-content {
  width: 680px;
  // border-radius: 30px;
  margin-top: 80px;
}
.show-download {
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  // .block {
  //   width: 120px;
  //   height: 120px;
  //   background-color: #fff;
  // }
  img {
    width: 708px;
  }
}
</style>
